import axios from "axios";
import api, { recordApi } from "services/api";
import { IUserData } from "store/reducers/UserRegister/AsyncActions/userRegister/types";

import { IAuthProps, IRegisterProps } from "./types";
export default class AuthenticationServices {
  public static async authenticationUser(props: IAuthProps) {
    const formData = new FormData();
    formData.append("username", props.email);
    formData.append("password", props.password);
    formData.append("grant_type", "password");
    formData.append("client_id", process.env.REACT_APP_CLIENT_ID as string);
    formData.append(
      "client_secret",
      process.env.REACT_APP_CLIENT_SECRET as string
    );

    return await api.post("/oauth2/o/token/", formData);
  }

  public static register = async (props: IRegisterProps): Promise<void> => {
    return recordApi.post("oauth2/register/", props);
  };

  public static updateUser = async (props: IRegisterProps): Promise<void> => {
    let updateFields = { ...props };
    delete updateFields["email"];

    return api.patch("/oauth2/users/" + props.id + "/", updateFields);
  };

  public static deleteUser = async (props: IRegisterProps): Promise<void> => {
    return api.delete(`/oauth2/users/${props.id}/`);
  };

  public static getUserById = async (id: number) => {
    return api.get<IRegisterProps>(`/oauth2/users/${id}/`);
  };

  public static getIdUser = async (): Promise<IRegisterProps> => {
    return api.get("oauth2/users/me/");
  };

  public static getUsers = async (params): Promise<IRegisterProps[]> => {
    return api.get("oauth2/users/?" + params);
  };
}
