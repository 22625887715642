import CardMenu from "components/cardMenu/cardMenu";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "./home.css";
const Home = (props) => {
  const history = useHistory();

  const { userData } = useSelector((state: any) => state.authentication);
  const userAdmin = userData && userData.type === "administrator";

  return (
    <div className="card-menu-content">
      <div className="card-menu-content-background-opacity">
        <div className="card-menu-container">
          <h1>Administração</h1>
          <br />
          <h1>de Turmas</h1>

          <div className="cards">
            <CardMenu
              title="Gerenciar Usuários"
              subTiltle="cadastrar, alterar e remover usuários da plataforma."
              buttonTitle="Gerenciar"
              action={() => history.push("/admin/users")}
            />

            <CardMenu
              title="Gerenciar Alunos"
              subTiltle="cadastrar, alterar e remover discentes."
              buttonTitle="Gerenciar"
              action={() => history.push("/groups/students")}
            />

            {userAdmin ? <CardMenu
              title="Gerenciar Escolas"
              subTiltle="cadastrar, alterar e remover escolas."
              buttonTitle="Gerenciar"
              action={() => history.push("/schools")}
            /> : <></>}


            <CardMenu
              title="Gerenciar Classes"
              subTiltle="cadastrar, alterar e remover classes."
              buttonTitle="Gerenciar"
              action={() => history.push("/groups/class")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
