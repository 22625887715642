import React, { FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import EditOutlineIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

import TitlePage from "components/Title/Title";

import {
  listUsersPaged,
} from "store/reducers/UserRegister/AsyncActions/userRegister";
import { IUserData } from "store/reducers/UserRegister/AsyncActions/userRegister/types";
import { findGroups, findGroupsSchools, loadAllCities, loadAllSchools } from "store/reducers/Groups/AsyncActions";

import { getEmptyUserData } from "utils/userDataConverter";

import { SearchIconCustom } from "./Style";
import { ITbodyTr, Table } from "components/Table";
import { IItemHeader } from "components/Table/TableHeader";
import SchoolDialog from "./SchoolDialog";
import SchoolDeleteDialog from "./SchoolDeleteDialog";
import { getEmptySchoolDataRegister } from "utils/schoolDataConverter";

const INITIAL_DATA_PAGINATION = {
  count: 0,
  next: "",
  previous: "",
  results: [],
};

export default function SchoolList() {
  const dispatch = useDispatch();
  const { schools } = useSelector((state: any) => state.groups);
  const { cities } = useSelector((state: any) => state.groups);

  const [searchByName, setSearchByName] = useState<string>("");
  const [searchByProfile, setSearchByProfile] = useState<string>("todos");
  const [dataSchoolsFiltered, setDataSchoolsFiltered] = useState<ITbodyTr[]>([])
  const [headersTable, setHeaderTable] = useState([
    {
      id: 1,
      title: 'COD',
      //with_order: 'init',
      //key_of_search: 'nome',
    },
    {
      id: 2,
      title: 'Name',
      //with_order: 'init',
      //key_of_search: 'sobrenome',
    },
    {
      id: 3,
      title: 'Cidade',
      //with_order: 'init',
      //key_of_search: 'sobrenome',
    },
    {
      id: 4,
    }
  ] as IItemHeader[])

  const [openDialogAdd, setOpenDialogAdd] = React.useState(false);
  const [openDialogDelete, setOpenDialogDelete] = React.useState(false);



  const [configPagination, setConfigPagination] = useState(INITIAL_DATA_PAGINATION);
  const [page, setPage] = useState(1);

  const [dataForm, setDataForm] = useState<any>(getEmptySchoolDataRegister());
  const [citySchema, setCitySchema] = useState<any[]>([]);


  useEffect(() => {
    dispatch(loadAllSchools());
    dispatch(loadAllCities());
  }, []);


  useEffect(() => {
    getStructuredDataCities()
  }, [cities]);

  const handleSearch = (event: FormEvent) => {
    event.preventDefault()

    dispatch(findGroupsSchools({
      type: "school",
      name: searchByName
    }));
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    dispatch(
      listUsersPaged(
        newPage,
        searchByProfile === 'todos' ? undefined : searchByProfile,
        setConfigPagination,
        searchByName.trim() ? searchByName : ''
      ));
  };

  const getButtonsOfActionsForTable = (school: any) => (
    <>
      <Button
        variant="outlined"
        color="inherit"
        size="small"
        aria-label="edit"
        startIcon={<EditOutlineIcon style={{ fontSize: '2rem' }} />}
        onClick={() => {
          setOpenDialogAdd(true);
          setDataForm(school);
        }}
      >
        Editar
      </Button>

      <Button
        variant="outlined"
        color="inherit"
        size="small"
        aria-label="delete"
        onClick={() => {
          setOpenDialogDelete(true);
          setDataForm(school);
        }}
      >
        <DeleteOutlineIcon style={{ fontSize: '2rem' }} />
      </Button>
    </>
  );



  const getStructuredDataSchoolForTable = () => {
    const data = schools.map((school: any) => {
      return {
        tbodyTrTd: [
          { element: school.id },
          { element: school.name },
          { element: citySchema[school.parent] },
          { element: getButtonsOfActionsForTable(school) },
        ],
      };
    });

    setDataSchoolsFiltered(data);
  };

  const getStructuredDataCities = () => {
    const transformedData = cities.reduce((acc, city) => {
      acc[city.id] = city.name;
      return acc;
    }, {});
    setCitySchema(transformedData);
  };


  useEffect(() => getStructuredDataSchoolForTable(), [schools])

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div style={{ width: '90vw', margin: '2rem' }}>
        <TitlePage
          title={"Gerenciamento de Escolas"}
          subtitle={schools.length + " registro(s) encontrado(s)"}
          buttonTitle="Adicionar Escola"
          buttonAction={() => {
            setDataForm(getEmptyUserData());
            setOpenDialogAdd(true);
          }}
        />

        <form onSubmit={handleSearch} id="filters_user" style={{ display: 'flex', alignItems: 'center', gap: 10, padding: '2rem 0' }}>
          <TextField
            style={{ flex: 6 }}
            size="small"
            variant="outlined"
            placeholder="Pesquisar por nome"
            value={searchByName}
            onChange={(e) => setSearchByName(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIconCustom />
                </InputAdornment>
              ),
            }}
          />

          <Button
            variant="contained"
            size="medium"
            style={{ flex: 1 }}
            type="submit"
            form="filters_user"
          >
            Pesquisar
          </Button>

          <Button
            variant="contained"
            color="secondary"
            size="medium"
            style={{ flex: 1 }}
            onClick={() => setSearchByName("")}
          >
            Limpar
          </Button>
        </form>

        <Table
          headers={headersTable}
          tbodyTrs={dataSchoolsFiltered}
          pagination={{
            numberActualPage: page,
            rowsPerPage: 10,
            totalRecords: schools.length,
            handlePaginationChange: handleChangePage
          }}
          serverSide={false}
          setHeaders={setHeaderTable}
        />

        <SchoolDialog
          open={openDialogAdd}
          setOpen={setOpenDialogAdd}
          dataForm={dataForm}
          setDataForm={setDataForm}
        />
        <SchoolDeleteDialog
          open={openDialogDelete}
          setOpen={setOpenDialogDelete}
          dataForm={dataForm}
          refreshFunction={undefined}
        />
      </div>
    </div>
  );
}
