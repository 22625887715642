import React from "react";
import { Modal } from "components/Modal";
import ClassForm from "../ClassForm";
import { GroupsInterface } from "model/Groups/interfaces/groups";
import AddAssessmentForm from "../AddAssessmentForm";

interface Props {
  classToEdit: GroupsInterface;
  open: boolean;
  onClose: () => void;
}
const AddAssessmentClassModal: React.FC<Props> = ({
  classToEdit,
  open,
  onClose,
}) => {
  const handleEditClass = () => {
    // classServices.deleteGroups(classToDelete.id!);
  };
  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      title="Vincular Avaliação"
      withAction={false}
    >
      <AddAssessmentForm
        type="edit"
        initialDataEdit={classToEdit}
        alert={() => {}}
        onSuccess={() => {
          onClose();
        }}
      />
    </Modal>
  );
};

export default AddAssessmentClassModal;
