import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "store";
import Routes from "./routes";
import { ToastContainer } from "react-toastify";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { theme } from "components/Admin/Themes";
import Navbar from "components/Navbar";
import Footer from "pages/Admin/sections/Footer";

import { useAuth } from "@cenpe/auth";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./assets/globalStyles.css"

export default function App() {
  const { check } = useAuth.getState();

  function onRedirect() {
    sessionStorage.clear();
  }

  useEffect(() => {
    setInterval(() => {
      check({ withRedirectSSO: true, onRedirect });
    }, 1000);
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Navbar
            titleApp="Administração de Turmas"
            itemsOfNavigation={[
              {
                name: 'Início',
                route: '/',
                isSelected: true,
                isExclusive: true,
              },
              {
                name: 'Usuários',
                route: '/admin/users',
                isSelected: false
              },
              {
                name: 'Alunos',
                route: '/groups/students',
                isSelected: false
              },
              {
                name: 'Escolas',
                route: '/schools',
                isSelected: false
              },
              {
                name: 'Classes',
                route: '/groups/class',
                isSelected: false
              },
            ]}
          />

          <Routes />
          <ToastContainer
            position="top-right"
            theme="colored"
            autoClose={2000}
          />
          {/*<Footer />*/}
          <ToastContainer
            position="top-right"
            theme="colored"
            autoClose={2000}
          />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
}
