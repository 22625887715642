import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSchool, loadAllCities, updateSchool } from "store/reducers/Groups/AsyncActions";
import { getEmptySchoolData } from "utils/schoolDataConverter";
import { GroupsInterface } from "model/Groups/interfaces/groups";

export default function SchoolDialog({
  open,
  setOpen,
  dataForm,
  setDataForm,
}) {
  const dispatch = useDispatch();
  const [city, setCity] = useState<number | null>(-1)
  const { cities } = useSelector((state: any) => state.groups);

  const handleDataForm = (position: string, value: unknown) => {
    setDataForm({ ...dataForm, [position]: value });
  };


  useEffect(() => {
    dispatch(loadAllCities());
  }, []);

  const handleSaveSchool = (e: React.FormEvent) => {
    e.preventDefault();

    if (!dataForm.id) {
      dispatch(createSchool({ name: dataForm.name, parent: Number(dataForm.parent), type: "school" }));
    } else {
      dispatch(updateSchool(dataForm));
    }
    cleanFormField(dataForm);
  };

  const cleanFormField = (e: React.FormEvent) => {
    setDataForm(getEmptySchoolData());
    setCity(-1)
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        keepMounted
        maxWidth="md"
        fullWidth
        onClose={() => setOpen(false)}
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Typography variant="h4">
            {dataForm?.id ? 'Editar Escola' : 'Cadastrar Escola'}
          </Typography>
        </DialogTitle>

        <form onSubmit={handleSaveSchool}>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <InputLabel id="nome">Nome:</InputLabel>
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={dataForm.name}
                  onChange={(e) => handleDataForm("name", e.target.value)}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <InputLabel>Cidade</InputLabel>
                <FormControl fullWidth>
                  <Select
                    name="cidade"
                    labelId="cidade"
                    required
                    value={dataForm.parent}
                    onChange={(e) => handleDataForm("parent", e.target.value)}
                  >
                    <MenuItem value={-1} disabled>
                      Selecione
                    </MenuItem>
                    {cities.map((city: GroupsInterface) => {
                      return (
                        <MenuItem key={city.id} value={city.id}>
                          {city.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button onClick={(e) => cleanFormField(e)} color="error">
              Cancelar
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Salvar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div >
  );
}
